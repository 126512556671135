import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
const APIURL = process.env.REACT_APP_API_URL;
const SUPRATVURL = process.env.REACT_APP_SUPRATV_URL;

interface SupraTVFrontImageProps {
  tveid: string;
  src: string;
  alt: string;
  fallback: string;
  overlayText?: string;
  watched?: boolean;
  resolution?: string;
}

const SupraTVFrontImage: React.FC<SupraTVFrontImageProps> = ({
  tveid,
  src,
  alt,
  fallback,
  overlayText,
  watched,
  resolution,
}) => {
  const [bgImage, setBgImage] = useState(src);
  const [isWatched, setIsWatched] = useState(watched);
  const token = sessionStorage.getItem("supratvtoken") || "";

  const handleWatchedToggle = () => {
    setIsWatched((prev) => !prev);
    axios
      .post(
        APIURL + "/supratv/watched/" + tveid,
        { watched: isWatched },
        {
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response: any) => {
        console.log(response);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const getResolutionLogo = () => {
    if (resolution) {
      const numericResolution = parseInt(resolution, 10);
  
      if (numericResolution >= 8000) {
        return '8k';
      } else if (numericResolution >= 4000) {
        return '4k';
      } else if (numericResolution >= 1080) {
        return '1080p';
      } else if (numericResolution >= 720) {
        return '720p';
      } else if (numericResolution > 1) {
        return '480p';
      }
    }
  
    return null; // Ensures a fallback value when resolution is not valid or not provided
  };

  const resolutionLogo = getResolutionLogo();

  return (
    <div
      style={{
        width: '250px',
        height: '350px',
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontSize: '20px',
        fontWeight: 'bold',
        textShadow: '1px 1px 5px rgba(0, 0, 0, 0.7)',
        position: 'relative',
      }}
      role="img"
      aria-label={alt}
      onError={() => setBgImage(fallback)}
    >
      {/* Resolution Logo */}
      {resolutionLogo && (
        <div
          style={{
            width: '40px',
            height: '40px',
            position: 'absolute',
            top: '0px',
            right: '0px',
            backgroundImage: `url(${SUPRATVURL}/images/icons/${resolutionLogo}.png)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            color: 'white',
            padding: '0px',
            borderRadius: '5px',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
        >
        </div>
      )}

      {overlayText && (
        <div
          style={{
            position: 'absolute',
            bottom: '0px',
            left: '10px',
            right: '10px',
            padding: '5px',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            borderRadius: '5px 5px 0px 0px',
            fontSize: '12px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span style={{ textAlign: 'left' }}>{overlayText}</span>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleWatchedToggle();
            }}
            sx={{ color: isWatched ? 'green' : 'red' }}
          >
            {isWatched ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default SupraTVFrontImage;