import React, { useState } from 'react';

interface SupraTVSeriesImageProps {
    tveid: string;
    src: string;
    alt: string;
    fallback: string;
    overlayText?: string;
    watched?: boolean;
}

const SupraTVSeriesImage: React.FC<SupraTVSeriesImageProps> = ({
    tveid,
    src,
    alt,
    fallback,
    overlayText,
}) => {
    const [bgImage, setBgImage] = useState(src);

    return (
        <div
            style={{
                width: '250px',
                height: '350px',
                backgroundImage: `url(${bgImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontSize: '20px',
                fontWeight: 'bold',
                textShadow: '1px 1px 5px rgba(0, 0, 0, 0.7)',
                position: 'relative',
            }}
            role="img"
            aria-label={alt}
            onError={() => setBgImage(fallback)}
        >
            {overlayText && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: '0px',
                        left: '10px',
                        right: '10px',
                        padding: '5px',
                        textAlign: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        borderRadius: '5px 5px 0px 0px',
                        fontSize: '12px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    {overlayText}
                </div>
            )}
        </div>
    );
};

export default SupraTVSeriesImage;